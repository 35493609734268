import React, { Fragment, useState } from 'react';
import { useEffect, useCallback, useRef } from 'react';
import logo from '../public/images/logo-b.png';
import menu from '../public/images/menu-icon.png';
import Ahmedabad from '../public/images/ahmedabad.png';
import Banglore from '../public/images/banglore.png';
import Delhi from '../public/images/delhi.png';
import Jaipur from '../public/images/jaipur.png';
import Kolkata from '../public/images/kolkata.png';
import Mumbai from '../public/images/mumbai.png';
import Pune from '../public/images/pune.png';
import { withRouter } from 'react-router';
// import OverlayMenu from 'react-overlay-menu';
import OverlayMenu from '../common_components/OverlayMenu/dist/react-overlay-menu';
import NacigationMenu from '../common_components/Menu/NacigationMenu';
import Link from 'next/link';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { bindActionCreators } from 'redux';
import * as productActions from '../actions/productActions';
import * as companyActions from '../actions/companyActions';
import * as homePageActions from '../actions/homePageActions';
import ReactTooltip from 'react-tooltip';
import { CgProfile } from "react-icons/cg"
import * as UserAction from "../actions/userActions";
import { usePopper } from 'react-popper';
import { BiCurrentLocation } from "react-icons/bi";
import { HiOutlineChevronDown } from "react-icons/hi"
import ReactGA from 'react-ga';
import Image from 'next/image';
import Router, { useRouter } from "next/router";
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { MdOutlineCall } from 'react-icons/md';
import { MdEmail } from "react-icons/md"
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import { AiFillInstagram } from 'react-icons/ai';
import { AiOutlineMenu } from 'react-icons/ai';
import { GrLocation } from 'react-icons/gr';
import { IoSearchOutline } from 'react-icons/io5'
import * as serviceActions from "../actions/serviceActions";
import { city } from '../api';
import { FaAngleDown } from "react-icons/fa"
import { HANDLE_SELECTED_CITY } from '../actions/types';
import Analytics from '../common_components/Common/Analytics';
import { isDesktop, isMobile, isMobileOnly } from "react-device-detect";



const DefaultHeader = ({ history }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const baseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const path = '';
  const toolref = useRef();
  const boxref = useRef();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [loadCommon, setLoadcommon] = useState(false);
  const [selectedcommon, setSelectedCommon] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [nearby, setNearBy] = useState([])
  const [showCityOption, setShowCityOptions] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [subCityDetected, setSubCityDetected] = useState(false);
  const [subCity, setSubCity] = useState([])
  const [selectedCity, setSelectedCity] = useState(
    //   {
    //   label: "Ahmedabad",
    //   state: "61e66a7a2e0f24388ffccc13",
    //   value: "Ahmedabad",
    //   _id: "61e66cd92e0f24388fff2b0c",
    // }
  );

  const [popupCity, setPopCity] = useState(
    selectedCity ? selectedCity.label : ''
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [cityLabel, setCityLabel] = useState(null);
  const [filtercity, setFilterCity] = useState('');
  const [opensearch, setOpenSearch] = useState(false);
  const [openlocation, setOpenLocation] = useState(false);
  const [popular, setpopular] = useState([]);
  const [popcityOptions, setPopularCityOptions] = useState([]);
  const [datafetched, setDatafetched] = useState(false);
  const [badgeValue, setBadgeValue] = useState(0);
  const [savedValue, setSavedvalue] = useState(0);
  const [popularcity, setPopularCity] = useState([
    { name: 'Ahmedabad', state: "61e66a7a2e0f24388ffccc13", _id: '649e834e0ae8203a2152a521',code: 'Ahmedabad', coordinate: [72.5825, 23.03957] },
    { name: 'Bengaluru', state: "61e66a7b2e0f24388ffcccea", _id: '649e83670ae8203a2152ac4f',code: 'Bengaluru', coordinate: [77.55318, 12.96626] },
    { name: 'Delhi', state: "61e66a7a2e0f24388ffccbde", _id: '649e834c0ae8203a2152a491',code: 'Delhi', coordinate: [77.08626, 28.66664] },
    { name: 'Jaipur', state: "61e66a7c2e0f24388ffcce29", _id: '649e83ac0ae8203a2152c045',code: 'Jaipur', coordinate: [75.78961, 26.8849] },
    { name: 'Kolkata', state: "61e66a7d2e0f24388ffccef3", _id: '649e83f60ae8203a2152d64c',code: 'Kolkata', coordinate: [88.3766, 22.57487] },
    { name: 'Mumbai', state: "61e66a7b2e0f24388ffccd53", _id: '649e83920ae8203a2152b8d9',code: 'Mumbai', coordinate: [72.86049, 19.05911] },
    { name: 'Pune', state: "61e66a7b2e0f24388ffccd53", _id: '649e83950ae8203a2152b98f',code: 'Pune', coordinate: [73.85799, 18.53121] }
  ])


  const { cities,backgroundBannerLpImages, citydata, cityloading, resetvehiclesearch } = useSelector((state) => state.home);
  const selectInputRef = useRef();
  const dispatch = useDispatch();
  const { logoutUser, loadUser } = bindActionCreators(UserAction, dispatch);
  const { addlocal, clearlocal } = bindActionCreators(serviceActions, dispatch);
  const { localstorageloading, localStorageData } = useSelector((state) => state.service);
  const { user, isAuthenticated } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    if (!datafetched) {
      loadUser()
      setDatafetched(true)
    }
  }, [user])

  const { getAllCompanyListWithoutPagination } = bindActionCreators(
    companyActions,
    dispatch
  )

  const { getMainVehicles } = bindActionCreators(productActions, dispatch);
  const { getCities, addNearbyCities, getStates, getPopularVehicles, popularVehicles, applyFilter, cityselect, getNearbyCities, getNearByCoordinates, getCitybyCoordinates, saveHeaderSearch } = bindActionCreators(
    homePageActions,
    dispatch
  )

  useEffect(() => {
    if (openPopup) {
      setTimeout(() => {
        setOpenPopup(false);
      }, 5000);
    }
  }, [openPopup])

  useEffect(() => {
    if (resetvehiclesearch == true) {
      selectInputRef.current.select.clearValue();
    }
  }, [resetvehiclesearch])



  const HandleLocation = () => {
    LocationApi();
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const value = JSON.parse(localStorage.getItem("currentLocationGeo"))
      // console.log("value")
      if (value?._id) {
        // console.log("enter")
        setSelectedCity({
          label: value.value,
          value: value?.value,
          _id: value?._id,
          state: value?.state,
          coordinate: value?.coordinate
        })
        cityselect(value)
        if (value?.subCity) setSubCityDetected(true)
      }
      else LocationApi()
      // else setSubCityDetected(false)
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('saved_vehicles')) {
      const value = JSON.parse(localStorage.getItem('saved_vehicles'))
    }
  }, [])

  useEffect(async() => {
    if (cities.length  >  0) {
      var city = [];
      cities.map((data) => {
        let cityObj = {
          label: data.name,
          value: data?.maincitycode ? data.maincitycode : data.name,
          _id: data._id,
          state: data.state,
          coordinate: data?.coordinate?.coordinates
        }
        city.push(cityObj);
      })

      setCityOptions(city)
    } else {
      getCities().then((res)=>{
        console.log(res, "<=====res")
      })
    }
  }, [cities])

  useEffect(() => {
    // const popular = popularcity;/
    // const finalpopular = [];
    const locationfinder = {
      label: <div onClick={() => HandleLocation()}>
        <span className='pe-2' style={{ marginTop: "-1%" }}><BiCurrentLocation /></span>
        Use Current Location
      </div>,
      value: null,

    }
    const optionsTitle = {
      label: "POPULAR CITIES",
      value: '',
      isDisabled: true
    }
    // finalpopular.push(optionsTitle);
    // console.log(finalpopular);
    var optionsArray = [];
    optionsArray.push(locationfinder)
    optionsArray.push(optionsTitle)
    popularcity.map((city) => {

      let dispCity = Ahmedabad;
      if (city.name == 'Ahmedabad') {
        dispCity = Ahmedabad;
      } else if (city.name == 'Bengaluru') {
        dispCity = Banglore;
      } else if (city.name == 'Delhi') {
        dispCity = Delhi;
      } else if (city.name == 'Jaipur') {
        dispCity = Jaipur;
      } else if (city.name == 'Kolkata') {
        dispCity = Kolkata;
      } else if (city.name == 'Mumbai') {
        dispCity = Mumbai;
      } else if (city.name == 'Pune') {
        dispCity = Pune;
      } else {
        dispCity = Ahmedabad;
      }
      const found = cities && cities.find((e) => e.name == city.name)

      optionsArray.push({
        label:
          <div>
            <Image
              src={dispCity}
              // src = { Ahmedabad }
              alt="pop-img"
              width="20%"
              height="20%"
              style={{ width: "15%", paddingRight: "4%", marginTop: "-3%" }} />
            &nbsp; {city.name}
          </div>,
        value: city.name,
        _id: city._id,
        state: city.state,
        coordinate: city?.coordinate
      })
    })

    setPopularCityOptions(optionsArray);
  }, [cities, cityOptions])

  useEffect(()=>{
    if(backgroundBannerLpImages && backgroundBannerLpImages.length > 0){
      let locationData = {};
      backgroundBannerLpImages.map((e,i)=>{
        locationData= {
            label:e.city_name,
            value:e.city_name,
            _id:e._id,
            state:"",
            subCity:false
          }
      })
      setSelectedCity(locationData)
      localStorage.setItem("currentLocationGeo", JSON.stringify(locationData))
    }
  },[backgroundBannerLpImages])

  useEffect(() => {
    if (!loadCommon) {
      let filterData = {
        order_by: 6,
        limit: 3
      }
      popularVehicles().then((res) => {
        const vehiclesData = res?.data?.result;
        const popular = vehiclesData?.docs.slice(3, 6)  // Show 4th, 5th & 6th vehicles from Popular Vehicles array of 7 vehicles
        const finalpopular = [];
        const optionsTitle = { 
          title: "Popular vehicles"
        }
        finalpopular.push(optionsTitle);
        popular?.map((element) => {
          element.type = 1;
          finalpopular.push(element);
        })
        var optionsArray = [];
        finalpopular.map((vehicle) => {
          optionsArray.push({
            "label": vehicle.companyData ?
              <div className=' justify-content-center'><picutre><img src={vehicle.image[0]} alt="pop-img" width="10%" height="10%" /> &nbsp;&nbsp; {vehicle?.companyData?.vehicle_prefix ? vehicle?.companyData?.vehicle_prefix+' '+ vehicle?.title : vehicle?.companyData?.title + '  ' + vehicle.title}</picutre> </div>
              : "POPULAR VEHICLES",
            "value": vehicle?.companyData?.vehicle_prefix ? vehicle?.companyData?.vehicle_prefix +' '+ vehicle?.title  : vehicle?.companyData?.title + '  ' + vehicle?.title,
            "slug": vehicle.slug ? vehicle.slug : "",
            "type": vehicle.type ? vehicle.type : "",
            "isdisabled": vehicle.slug ? false : true
          })
        })
        setpopular(optionsArray);
      })
      getAllCompanyListWithoutPagination().then((res) => {
        const company = res?.data?.result;
        const demo = company?.map((c) => {
          c.type = 2;
          return c;
        });
        getMainVehicles().then((res) => {
          const product = res?.data?.result;
          const vehicle = product?.map((c) => {
            c.type = 1;
            return c;
          });
          const allItems = demo?.concat(vehicle);
          // console.log(allItems)
          setSelectedCommon(allItems);
        });
      });
      setLoadcommon(true);
    }
  }, [loadCommon, getAllCompanyListWithoutPagination, getMainVehicles, popularVehicles]);

  const HandleEvents = (data) => {
    // console.log(data)
    // console.log(data.name)
    const Analyticsprops = {
      GA: true,
      title: `${data} - Header `,
      category: 'Header-Events',
      action: `${data} - Header `,
      label: `${data} - Header clicked!`,
    }
    Analytics(Analyticsprops)

  }

  const handlecommonchange = (data) => {

    const searchObj = {
      search: showOptions ? data?.label : data?.value,
      type: data?.type
    }
    if (selectedCity) searchObj.location = selectedCity.label
    saveHeaderSearch(searchObj)
    if (data && data.type === 2) {
      Router.push(`/electric-vehicle-companies/${data.slug}`);
    }
    if (data && data.type === 1) {
      setOpenSearch(false)
      Router.push(`/product/${data.slug}`);
    }
    HandleEvents("Search dropdown")
  };



  const handleInputchange = useCallback((value) => {
    if (value.length > 1) {
      setShowOptions(true);
      setShowCityOptions(true);
    } else {
      setShowOptions(false);
      setShowCityOptions(false);
    }
  }, []);

  const handleCityChange = (data) => {

    if (data?.value !== null) {
      
      data.label = data?.label?.length > 9 ? data.label.slice(0, 9) + '...' : data.label
      const customData = {
        label: data?.value,
        value: data?.value,
        _id: data?._id,
        state: data?.state,
        code: data?.code,
        coordinate: data?.coordinate,
        subCity: false
      }
      localStorage.setItem("currentLocationGeo", JSON.stringify(customData))
      setSelectedCity(data);
      setCityLabel(null);
      setFilterCity(data.value);
      setSubCityDetected(false);
      cityselect(customData);
      setPopCity(data.value);
      dispatch({
        type: HANDLE_SELECTED_CITY,
        payload: data,
      });
      HandleEvents("City dropdown")
      // console.log(data)

      // window.location.href = `${baseUrl}/electric-vehicles`;
     
      
      // console.log(`${baseUrl}/electric-vehicles`, "fffff")
    }
  };

  const LocationApi = () => {
    if (!navigator.geolocation) {
      // setStatus('Geolocation is not supported by your browser')

    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLat(position.coords.latitude);
        const latti = position.coords.latitude
        const langi = position.coords.longitude
        setLng(position.coords.longitude);
        getCitybyCoordinates(latti, langi).then((res) => {
          const found = res.data.result[0]?.megaCity === 1 ? res.data.result[0] : res.data.result[1]
          const cityDetails = {
            label: found?.name,
            value: found?.name,
            _id: found?._id,
            state: found?.state,
            code: found?.code,
            coordinate: found?.coordinate?.coordinates,
            subCity: res.data.result.length > 1 ? true : false
          }

          if (res?.data.result.length > 1) {
            const data = res.data.result[0]
            localStorage.setItem("subCity", JSON.stringify({
              label: data?.name,
              value: data?.name,
              _id: data?._id,
              state: data?.state,
              code: data?.code,
              coordinate: data?.coordinate?.coordinates,
            }))
            setSubCityDetected(true)
            setSubCity(res.data.result[0])
          }
          localStorage.setItem("currentLocationGeo", JSON.stringify(cityDetails))
          setSelectedCity(cityDetails)
          cityselect(cityDetails)
          dispatch({
            type: HANDLE_SELECTED_CITY,
            payload: cityDetails,
          })
        })
      })
    }
  }

  useEffect(() => {
    if (selectedCity?.coordinate) {
      // console.log(subCityDetected, subCity, selectedCity, 'subCityDetected')
      getNearByCoordinates(selectedCity?.coordinate[1], selectedCity?.coordinate[0]).then((res) => {
        const value = res?.data?.result;
        const locationfinder = {
          label: <div onClick={() => HandleLocation()}>
            <span className='pe-2' style={{ marginTop: "-1%" }}><BiCurrentLocation /></span>
            Use Current Location
          </div>,
          value: null,   
        }
        const options = []
        options.push(locationfinder)
        options.push({
          label: "NEARBY CITIES",
          value: '',
          isDisabled: true
        })
        if (subCityDetected) {
          // console.log("detected")
          const subCity = JSON.parse(localStorage.getItem('subCity'))
          options.push(subCity)
        }
        value?.map((data) => {
          let cityObj = {
            label: data.name,
            value: data.name,
            _id: data._id,
            state: data.state,
            code: data?.code,
            coordinate: data?.coordinate?.coordinates
          }
          options.push(cityObj);
        })
        setNearBy(options)
        // setSubCityDetected(false)
      })
    }
  }, [selectedCity])

  const customStyles = {
    placeholder(base, state) {
      return {
        ...base,
        display: state.isFocused && 'none',
    
      }
    },

    menu: (provided) => ({
      ...provided,
      width: isMobile ? '285px' : '345px',
      zIndex: 9999,
    }),
    // menuList: (base) => ({
    //   ...base,
    //   width: '250px', 
    //   padding: 0, 
    // }),

    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isDisabled && 'white',
        color: isDisabled ? '#929292' : 'black',
        fontSize: isDisabled ? "12px" : "14px",
        "&:hover": {
          backgroundColor: isDisabled ? "white" : "#4A90E4",
          color: isDisabled ? '#929292' : "white"
        },
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontWeight: isDisabled ? "500" : "normal"
      }
    }
  }

  const customCityStyle = {
    placeholder(base, state) {
      return {
        ...base,
        display: state.isFocused && 'none',
      }
    },
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isDisabled && 'white',
        color: isDisabled ? '#929292' : 'black',
        fontSize: isDisabled ? "12px" : "14px",
        "&:hover": {
          backgroundColor: isDisabled ? "white" : "#4A90E4",
          color: isDisabled ? '#929292' : "white"
        },
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontWeight: isDisabled ? "500" : "normal",
        overFlow: "hidden"
      }
    }
  }

  const customMobileStyles = {
    placeholder(base, state) {
      return {
        ...base,
        display: state.isFocused && 'none',
      }
    },
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: 'white',
        color: isDisabled ? '#929292' : 'black',
        fontSize: isDisabled ? "12px" : "14px",
        // width:'214px',
        "&:hover": {
          backgroundColor: isDisabled ? "white" : "#4A90E4",
          color: isDisabled ? '#929292' : "white"
        },
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontWeight: isDisabled ? "500" : "normal"
      }
    }
  }

  const onLogout = () => {
    setTimeout(() => {
      Router.push("/");
      localStorage.removeItem("token");
      toast.success("You are Logged Out");
      logoutUser();
    }, 1000);
  }
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const value = JSON.parse(localStorage.getItem("saved_vehicles"));
      setSavedvalue(value ? value.length : 0)

    }
  }, [localStorageData])

  

  function SingleValue(props) {
    const { children, ...rest } = props;
    const { selectProps } = props;
    if (selectProps.menuIsOpen) return <Fragment></Fragment>;
    return <components.SingleValue {...rest}>{children}</components.SingleValue>;
  }


  return (
    <Fragment>
      <header
        className={classNames("sticky-top bg-white", {
          "shadow-sm": path !== "/",
        })}
        style={{ zIndex: "1050" }}
      >
        <div className="container d-block d-md-none py-3  py-lg-4 px-md-0">
          <div className=" d-flex justify-content-between align-items-center ">
            <div className="d-flex justify-content-start align-items-center">
              <OverlayMenu
                open={openMenu}
                // open={true}
                onClose={(e) => setOpenMenu(!openMenu)}
                // right={true}
                left={true}
              >
                <NacigationMenu closeMenu={(e) => setOpenMenu(!openMenu)} />
              </OverlayMenu>
              <div className="">
                <i
                  className="c-pointer right-menu   mt-md-1 mt-lg-0"
                  onClick={(e) => {
                    setOpenMenu(!openMenu);
                    setOpenSearch(false);
                  }}
                >
                  <AiOutlineMenu style={{ fontSize: "20px" }} />
                </i>
                {/* <Image
                src={menu}
                width='20'
                height='14'
                alt='logo'
                className=''
                
              /> */}
              </div>
              <div className="d-inline-block ms-2">
                <Link href="/">
                  <a>
                    <Image
                      src={logo}
                      alt="logo"
                      width="100px"
                      height="32px"
                      className="main-logo"
                      onClick={() => HandleEvents("Logo")}
                    />
                  </a>
                </Link>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-start">
              <div class="d-flex  justify-content-start me-2">
                <span onClick={() => {
                    setOpenLocation(!openlocation);
                    setOpenSearch(false);
                  }}>
                  <GrLocation className="" style={{ fontSize: "20px" }} />
                </span>
                {/* <span className='ms-1 bordered'>
                
                  {popupCity
                    ? popupCity.length > 9
                      ? popupCity.slice(0, 8) + '...'
                      : popupCity
                    : null}
                </span> */}
              </div>
              <div
                className="d-inline-block me-2"
                onClick={() => HandleEvents("Navigation Bar")}
              >
                <i
                  onClick={() => {
                    setOpenSearch(!opensearch);
                    setOpenLocation(false);
                  }}
                >
                  <IoSearchOutline style={{ fontSize: "20px" }} />
                </i>
              </div>
              <div className="d-flex align-items-center ">
                {user && isAuthenticated && localStorage.getItem("token") ? (
                  <div className="d-flex align-items-center">
                    <Link
                      replace
                      href={
                        user && user.role == 2
                          ? "/dealer-profile"
                          : "/user-profile"
                      }
                      onClick={(e) => {
                        closeMenu();
                        HandleEvents("Login/signup logo");
                      }}
                    >
                      <a>
                        <img
                          src={user.logo}
                          className="dealer-profile-pic"
                          alt={user.name + " logo"}
                        />
                      </a>
                    </Link>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 1.5 }} onClick={() => onLogout()}>
                        Logout
                      </Typography>
                    </Popover>
                    <HiOutlineChevronDown
                      variant="contained"
                      className="ms-2"
                      onClick={handleClick}
                    />
                  </div>
                ) : (
                  <div>
                    <Link href="/login">
                      <i>
                        <CgProfile style={{ fontSize: "20px" }} />
                      </i>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>

          {openlocation && (
            <div className="search-sec   col-10 mt-4  mb-2">
              <div className=" city-bar d-inline-block  ">
                  <Select
                    placeholder={null}
                    styles={customCityStyle}
                    value={selectedCity}
                    onInputChange={handleInputchange}
                    onChange={(e) => handleCityChange(e)}
                    aria-label="city-dropdown"
                    options={
                      showCityOption
                        ? cityOptions
                        : nearby.length > 1
                        ? nearby
                        : popcityOptions
                    }
                  ></Select>
              </div>
            </div>
          )}

          {opensearch && (
            <div className="search-sec   col-10 mt-4  mb-2">
       
              <div className="d-inline-block color-d-gray vehicle-bar">
                  <Select
                    placeholder="Search"
                    isSearchable
                    styles={customStyles}
                    onChange={handlecommonchange}
                    aria-label="vehicle-dropdown"
                    onInputChange={handleInputchange}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    noOptionsMessage={() => ""}
                    options={
                      showOptions
                        ? selectedcommon?.map((vehicle) => {
                            return {
                              label: vehicle.title,
                              slug: vehicle.slug,
                              type: vehicle.type,
                              search:
                                vehicle?.searchName !== null
                                  ? vehicle?.searchName
                                  : vehicle?.title,
                            };
                          })
                        : popular
                    }
                    isOptionDisabled={(option) => option.isdisabled}
                  ></Select>
              </div>
            </div>
          )}
        </div>
        <div className="border-bottom d-none d-md-block">
          <div className="header-container py-2 d-flex justify-content-between">
            <div>
              <div className="">
                <span className="border-right pe-3">
                  <span className="px-1 border rounded-2 color-d-gray">
                    {" "}
                    <MdOutlineCall />
                  </span>
                  <span
                    className="fs-12 ms-1  color-d-gray"
                    onClick={() => HandleEvents("call icon")}
                  >
                    <a
                      href="tel:+919099900348"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91 9099900348
                    </a>
                  </span>
                </span>
                <span className="ps-3">
                  <span className="pe-1 ms-2 border rounded-1 color-d-gray">
                    {" "}
                    <MdEmail />
                  </span>
                  <span
                    className="fs-12 ms-1 color-d-gray"
                    onClick={() => HandleEvents("mail icon")}
                  >
                    <a
                      href="mailto:info@evindia.online"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@evindia.online
                    </a>
                  </span>
                </span>
              </div>
            </div>
            <div>
              <span className="border-right pe-4">
                <a
                  href="https://www.facebook.com/evindia.online/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="link for EVINDIA facebook page"
                  onClick={() => HandleEvents("Facebook icon")}
                >
                  <i className="border color-d-gray px-1 rounded-2">
                    <FaFacebookF className="fs-14" />
                  </i>
                </a>
                <a
                  href="https://twitter.com/EVIndiaonline"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="link for EVINDIA twitter page"
                  onClick={() => HandleEvents("twitter icon")}
                >
                  <i className="border color-d-gray px-1 ms-3 rounded-2">
                    <FaSquareXTwitter className="fs-14" />
                  </i>
                </a>
                <a
                  href="https://www.linkedin.com/company/evindiaonline/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="link for EVINDIA linkedin page"
                  onClick={() => HandleEvents("linkedin icon")}
                >
                  <i className="border color-d-gray ms-3 px-1 rounded-2">
                    <FaLinkedinIn className="fs-14" />
                  </i>
                </a>
                <a
                  href="https://youtube.com/channel/UCywB8N5ChjnfTNrvS4q8ZIA"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="link for EVINDIA Youtube page"
                  onClick={() => HandleEvents("Youtube icon")}
                >
                  <i className="border color-d-gray px-1 ms-3 rounded-2">
                    <FaYoutube className="fs-14" />
                  </i>
                </a>
                <a
                  href="https://www.instagram.com/evindia.online/?utm_medium=copy_link"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="link for EVINDIA Instagram page"
                  onClick={() => HandleEvents("Instagram icon")}
                >
                  <i className="border color-d-gray px-1 ms-3 rounded-2">
                    <AiFillInstagram className="fs-14" />
                  </i>
                </a>
              </span>
              <Link href="/privacy-policy">
                <span
                  className="border-right px-4 fs-12 fw-500 color-d-gray c-pointer"
                  onClick={() => HandleEvents("privacy policy")}
                >
                  PRIVACY
                </span>
              </Link>
              <Link href="/termsandconditions">
                <span
                  className="border-right px-4 fs-12 fw-500 color-d-gray c-pointer"
                  onClick={() => HandleEvents("Terms and conditions")}
                >
                  TERMS & CONDITIONS
                </span>
              </Link>
              <Link href="/career">
                <span
                  className="border-right px-4 fs-12 fw-500 color-d-gray c-pointer"
                  onClick={() => HandleEvents("Career")}
                >
                  CAREER
                </span>
              </Link>
              <Link href="/contact-us">
                <span
                  className="border-right px-4 fs-12 fw-500 color-d-gray c-pointer"
                  onClick={() => HandleEvents("Contact Us")}
                >
                  CONTACT US
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="header-container d-none d-md-block py-3  py-lg-4">
          <div className=" w-100 col-12 d-flex align-items-center m-0">
            <span className="d-inline-block  pt-1 me-4">
              <Link href="/">
                <a>
                  <Image
                    src={logo}
                    alt="logo"
                    width="175px"
                    height="55px"
                    onClick={() => HandleEvents("Logo")}
                  />
                </a>
              </Link>
            </span>

            <div className="d-none d-lg-inline-block search-sec ">
              <div
                className="city-bar d-inline-block"
                style={{ width: "191px" }}
              >
                  <Select
                    placeholder="City"
                    styles={customCityStyle}
                    value={selectedCity}
                    // classNamePrefix="react-select"
                    className="react-select-container"
                    aria-label="city-dropdown"
                    onInputChange={handleInputchange}
                    onChange={(e) => handleCityChange(e)}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      SingleValue,
                    }}
                    menuShouldScrollIntoView={false}
                    options={
                      showCityOption
                        ? cityOptions
                        : nearby.length > 1
                        ? nearby
                        : popcityOptions
                    }
                  ></Select>
              </div>
              <div className=" d-inline-block color-d-gray vehicle-bar vehicle-bar-2">
                  <Select
                    ref={selectInputRef}
                    placeholder="Search EVs or Brands"
                    isSearchable
                    styles={customStyles}
                    onChange={handlecommonchange}
                    onInputChange={handleInputchange}
                    aria-label="vehicles-dropdown"
                    options={
                      showOptions
                        ? selectedcommon?.map((vehicle) => {
                            return {
                              label: vehicle.title,
                              slug: vehicle.slug,
                              type: vehicle.type,
                              value:
                                vehicle?.searchName !== null
                                  ? vehicle?.searchName
                                  : vehicle?.title,
                            };
                          })
                        : popular
                    }
                    isOptionDisabled={(option) => option.isdisabled}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    noOptionsMessage={() => ""}
                  ></Select>
              </div>
            </div>
            <div className=" d-none d-lg-block header-links">
              <div className="d-flex justify-content-between flex-row">
                <div className="d-none d-md-inline-block  py-2 c-pointer">
                  <Link href="/">
                    <a>
                      <span onClick={() => HandleEvents("Home page Link")}>
                        HOME
                      </span>
                    </a>
                  </Link>
                </div>
                <div className="d-none d-md-inline-block c-pointer py-2 dropdown">
                  <Link href="/electric-vehicles">
                    <a>
                      <span
                        onClick={() =>
                          HandleEvents("Electric Vehicle page Link")
                        }
                      >
                        E-VEHICLES
                      </span>
                      <HiOutlineChevronDown className="dropbtn ms-2" />
                    </a>
                  </Link>
                  <div className="dropdown-content border">
                    <Link href={"/electric-two-wheelers"}>
                      <a className="head">Two wheeler</a>
                    </Link>
                    <Link href={"/electric-scooters"}>
                      <a className="border-left ms-3 ps-3 sub-head">Scooter</a>
                    </Link>
                    <Link href={"/electric-bikes"}>
                      <a className="border-left  ms-3 ps-3 sub-head">Bike</a>
                    </Link>
                    <Link href={"/electric-cars"}>
                      <a className="head">Four wheeler</a>
                    </Link>
                    <Link href={"/electric-commercial-vehicles"}>
                      <a className="head">Commercial</a>
                    </Link>
                  </div>
                </div>
                <div className="d-none d-md-inline-block  py-2 c-pointer">
                  <Link href="/electric-vehicle-companies">
                    <a>
                      <span onClick={() => HandleEvents("Brands page Link")}>
                        BRANDS
                      </span>
                    </a>
                  </Link>
                </div>
                <div className="d-none d-md-inline-block c-pointer py-2">
                  <Link href="/compare">
                    <a>
                      <span onClick={() => HandleEvents("Compare page Link")}>
                        COMPARE
                      </span>
                    </a>
                  </Link>
                </div>
                <div className="d-none d-md-inline-block  py-2 c-pointer">
                  <Link href="/news">
                    <a>
                      <span onClick={() => HandleEvents("News page Link")}>
                        NEWS
                      </span>
                    </a>
                  </Link>
                </div>
                <div className="d-none d-md-inline-block  py-2 c-pointer">
                  <Link href="/blog">
                    <a>
                      <span onClick={() => HandleEvents("Blogs page Link")}>
                        BLOGS
                      </span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center  py-2">
              {user && isAuthenticated && localStorage.getItem("token") ? (
                <div>
                  <Link
                    replace
                    href={
                      user && user.role == 2
                        ? "/dealer-profile"
                        : "/user-profile"
                    }
                    onClick={(e) => {
                      closeMenu();
                      HandleEvents("Login/signup logo");
                    }}
                  >
                    <a>
                      <img
                        src={user.logo}
                        className="dealer-profile-pic"
                        alt={user.name + " logo"}
                      />
                      <span
                        aria-describedby={id}
                        className="ms-2 header-user-name fw-500"
                      >
                        {user.name.split(" ")[0].length < 5
                          ? user.name.split(" ")[0]
                          : user.name.split(" ")[0].slice(0, 5) + "..."}
                      </span>
                    </a>
                  </Link>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography
                      sx={{ p: 1.5 }}
                      className="c-pointer"
                      onClick={() => onLogout()}
                    >
                      Logout
                    </Typography>
                  </Popover>
                  <HiOutlineChevronDown
                    variant="contained"
                    className="ms-2 c-pointer"
                    onClick={handleClick}
                  />
                </div>
              ) : (
                <div>
                  <CgProfile style={{ fontSize: "18px" }} />
                  <Link href="/login">
                    <span
                      className="header-user-name ms-2 c-pointer"
                      onClick={() => HandleEvents("Login link")}
                    >
                      LOGIN
                    </span>
                  </Link>
                  <Link href="/signup">
                    <span
                      className="header-user-name  c-pointer"
                      onClick={() => HandleEvents("Signup link")}
                    >
                      /SIGNUP
                    </span>
                  </Link>
                </div>
              )}
            </div>
            {/* Element "style" not allowed as child of element "div" in this context */}
            <div
              className="d-flex align-items-center ms-4 c-pointer"
              onClick={() => HandleEvents("Saved vehicles icon")}
            >
                <Link href="/saved-vehicles">
                  {/* Element "style" not allowed as child of element "Span" in this context */}
                  <Badge
                    badgeContent={savedValue}
                    color="primary"
                    className="p-absolute"
                  >
                    <BookmarkBorderRoundedIcon
                    //  style={{width:"15px"}}
                    />
                  </Badge>
                </Link>
            </div>

            <OverlayMenu
              open={openMenu}
              // open={true}
              onClose={(e) => setOpenMenu(!openMenu)}
              right={true}
            >
              <NacigationMenu closeMenu={(e) => setOpenMenu(!openMenu)} />
            </OverlayMenu>

            {opensearch && (
              <div className="search-sec  d-lg-none d-inline-block col-10 mt-4 order-last mb-2">
                <div className=" city-bar d-inline-block  col-sm-6">
                    <Select
                      placeholder={null}
                      styles={customCityStyle}
                      value={selectedCity}
                      aria-label="city-dropdown"
                      onInputChange={handleInputchange}
                      onChange={(e) => handleCityChange(e)}
                      options={
                        showCityOption
                          ? cityOptions
                          : nearby.length > 1
                          ? nearby
                          : popcityOptions
                      }
                    ></Select>
                </div>
                <div className="col-6 col-sm-6 d-inline-block color-d-gray vehicle-bar">
                    <Select
                      placeholder="Search"
                      isSearchable
                      styles={customStyles}
                      aria-label="vehicle-dropdown"
                      onChange={handlecommonchange}
                      onInputChange={handleInputchange}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      noOptionsMessage={() => ""}
                      options={
                        showOptions
                          ? selectedcommon?.map((vehicle) => {
                              return {
                                label: vehicle.title,
                                value: vehicle.slug,
                                type: vehicle.type,
                              };
                            })
                          : popular
                      }
                      isOptionDisabled={(option) => option.isdisabled}
                    ></Select>
                </div>
              </div>
            )}
          </div>
        </div>
        {opensearch && openPopup && (
          <div className="popup-city">
            Current location:
            <br />
            {popupCity ? popupCity : null}
          </div>
        )}
      </header>
      {/* <div className='floating-icon'>
        <span className=''>
          <a
            href={router.pathname === '/bu4' ?
              'https://api.whatsapp.com/send/?phone=919099900348&text=Hi%2C+I+am+interested+in+buying+a+BU4+Vehicle&type=phone_number&app_absent=0' :
              router.pathname === '/ola' ?
              'https://api.whatsapp.com/send/?phone=919099900348&text=Hi%2C+I+am+interested+in+buying+a+OLA+Vehicle&type=phone_number&app_absent=0' :
              'https://api.whatsapp.com/send/?phone=919099900348&text=Hi%2C+I+am+interested+in+buying+an+Electric+Vehicle&type=phone_number&app_absent=0'
            }
            target='_blank' rel='noreferrer'>
            <svg id="_066" data-name="066" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48">
              <path id="Path_5470" data-name="Path 5470" d="M24,48A24,24,0,1,0,0,24,24,24,0,0,0,24,48Z" fill="#25d366" />
              <path id="Path_5471" data-name="Path 5471" d="M24.791,37.353h-.006a14.259,14.259,0,0,1-6.82-1.737L10.4,37.6l2.025-7.4a14.271,14.271,0,1,1,12.366,7.148Zm-6.475-4.321.433.257a11.844,11.844,0,0,0,6.037,1.653h0a11.863,11.863,0,1,0-10.048-5.559l.282.449-1.2,4.377Zm13.176-6.878a1.153,1.153,0,0,1,.488.321,2.982,2.982,0,0,1-.208,1.7,3.673,3.673,0,0,1-2.407,1.7,4.888,4.888,0,0,1-2.246-.141,20.456,20.456,0,0,1-2.033-.751,15.707,15.707,0,0,1-6.029-5.3L19,23.59l0,0A6.928,6.928,0,0,1,17.539,19.9,3.949,3.949,0,0,1,18.718,17l.07-.075a1.31,1.31,0,0,1,.951-.446c.238,0,.476,0,.683.013h.08c.208,0,.467,0,.722.611.1.236.242.586.394.956.307.747.646,1.572.706,1.692a.657.657,0,0,1,.03.625l-.05.1a2.025,2.025,0,0,1-.306.493c-.06.07-.121.145-.183.22-.123.149-.245.3-.352.405-.179.178-.364.371-.156.728a10.749,10.749,0,0,0,1.984,2.47,9.212,9.212,0,0,0,2.632,1.664c.1.042.177.077.236.106.356.179.564.149.772-.089s.891-1.041,1.129-1.4.476-.3.8-.178,2.08.982,2.437,1.16Z" fill="#fdfdfd" fill-rule="evenodd" />
            </svg>
          </a>
        </span>
      </div>  */}
    </Fragment>
  );

};
export default DefaultHeader;

